.breadcrumbs {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .step {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;

    &:not(:last-child) {
      margin-right: 110px;
      @media (max-width: 650px){
        margin-right: 44px;
      } 
    }

    .position {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border: 2px solid $secondary;
      border-radius: 50%;
      background-color: $white;
      font-size: 28px;
      color: $secondary;
    }

    .label {
      position: absolute;
      top: 48px;
      left: -50px;
      right: -50px;
      color: $secondary;
      text-transform: uppercase;
      text-align: center;
      @media (max-width: 650px){
        font-size: 10px;
        display: none;
      } 
    }

    &.selected {
      .position {
        background-color: $secondary;
        color: $white;
      }

      .label {
        @media (max-width: 650px){
          display: block;
        } 
        
        font-weight: bold;
      }
    }

    &.disabled {
      .position {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border: 2px solid $gray;
        border-radius: 50%;
        background-color: $white;
        font-size: 28px;
        color: $gray;
        cursor: not-allowed;
      }

      .position {
        background-color: $gray;
        color: $white;
      }

      .label {
        color: $gray;
        font-weight: bold;
      }

      &:not(:first-child)::after {
        content: ' ';
        width: 110px;
        @media (max-width: 650px){
          width: 44px;
        } 
        position: absolute;
        top: 50%;
        right: 44px;
        height: 2px;
        background: $gray;
      }
    }

    &:not(:first-child)::after {
      content: ' ';
      width: 110px;
      @media (max-width: 650px){
        width: 44px;
      } 
      position: absolute;
      top: 50%;
      right: 44px;
      height: 2px;
      background: $secondary;
    }
  }
}
