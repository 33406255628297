.selection-cheptel-assistance {
  .selection-departement {
    background-color: white;
  }

  .header-container {
    position: sticky;
    top: 0;
    z-index: 2;
  }
}