body {
  font-size: 14px;
  line-height: 21px;
  font-family: 'Roboto', sans-serif;
  color: $primary-dark;
}

h1 {
  font-size: 28px;
  line-height: 38px;
  font-family: 'Aldrich', sans-serif;
  font-weight: lighter;
  color: $secondary;
}

h2 {
  font-size: 22px;
  line-height: 32px;
  font-family: 'Aldrich', sans-serif;
  font-weight: lighter;
  color: $secondary;
}

h3 {
  font-size: 18px;
  line-height: 28px;
  font-family: 'Aldrich', sans-serif;
  font-weight: lighter;
}

h4 {
  font-size: 16px;
  line-height: 26px;
  font-family: 'Aldrich', sans-serif;
  font-weight: lighter;
}

h5 {
  font-size: 14px;
  line-height: 24px;
  font-family: 'Aldrich', sans-serif;
  font-weight: lighter;
}

h6 {
  font-size: 14px;
  line-height: 24px;
  font-family: 'Aldrich', sans-serif;
  font-weight: lighter;
}

.caption {
  font-size: 12px;
  line-height: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: lighter;
}

.light-link {
  font-size: 14px;
  line-height: 21px;
  color: $secondary;
  text-decoration: underline;
}

[ng-click],
a {
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }
}

[ng-click],
a,
a:active,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: none;
}
