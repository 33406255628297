.admin-techniciens {
  .form-recherche-exploitation {
    position: sticky;
    top: 20px;
  }

  .cheptels-affectes-header {
    position: sticky;
    top: 0;
    z-index: 2;
    &.header2 {
      top: 40px;
    }
  }
}