.input-search {
  align-items: center;
  background-color: $gray-light;
  border: 1px solid rgba(0,0,0,0);
  border-radius: 4px;
  display: flex;
  flex: 1;
  height: 28px;
  width: 300px;
  min-width: 300px;
  max-width: 300px !important;
  padding: 0 10px;
  margin-top: 4px;
  @media screen and (max-width: 1280px) {
    min-width: 150px;
  }

  input {
    background-color: transparent;
    border: none;
    color: rgba(0,0,0,0.54);
    flex: 1;
    padding: 0 10px;
    font-size: 16px;

    &:focus {
      outline: none;

      .search & {
        background-color: $gray-light;
      }
    }
  }

  .reset-icon {
    font-size: 16px;
  }
}
