.cloture-abonnement {

  .selection-form {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .codePays {
    display: inline-block;
    background-color: $gray;
    padding: 2px;
  }

  .choixInfo {
    display: inline-block;
    padding: 35px 45px 0 45px;
    font-size: 20px;
  }

  .btn-valider {
    height: 10px;
    margin-top: 20px;
    margin-left: 90px;
  }

  md-input-container .md-input, md-input-container md-select {
    width: 250px;
  }

  md-input-container .md-input-message-animation:not(.md-char-counter), md-input-container .md-char-counter:not(.md-char-counter) {
    padding-right: 5px;
    padding-left: 0;
    width: 200px;
  }

  .md-button.md-icon-button {
    display: none;
  }

  .md-datepicker-input-container {
    position: relative;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-right: 3px;
    margin-left: -5px;
  }

  .md-datepicker-input {
    font-size: 12px;
    margin-top: 1px;
    box-sizing: border-box;
    border: none;
    box-shadow: none;
    outline: none;
    background: transparent;
    max-width: 90px;
    min-width: 70px;
  }

  .content {
    border-top: 2px solid #ebebeb;
    padding: 40px 10px 10px 10px;
  }
}
