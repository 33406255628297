.bovin {
  flex: 1;
  display: flex;

  &.horizontal {
    flex-direction: column;
  }

  &.vertical {
    flex-direction: row;
  }
}
.main-view {
  display: contents;
}