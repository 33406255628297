.content {
  padding: 30px;
}

md-content {
  background-color: $white;
}

md-dialog {
  width: 500px;
}
