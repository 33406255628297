/* AUTH */
.adv-auth {
  display: flex;
  flex-direction: column;

  .input-container {
    margin-bottom: 20px;
  }

  .authent-form {
    //position: absolute;
    //right: 10rem;
    //width: 600px;
  }

  input,
  select {
    height: 45px;
    padding: 0 12px;
    border: none;
    border-radius: 2px;
    box-sizing: border-box;
    background-color: white;
  }

  select {
    &.placeholder {
      color: #757575;
    }

    option {
      color: $black;

      &[value=''][disabled] {
        display: none;
      }
    }
  }

  textarea {
    padding: 12px;
    border: none;
    border-radius: 2px;
    box-sizing: border-box;
    background-color: white;
  }

  p {
    text-align: justify;
  }

  .error-message,
  div[ng-messages] {
    color: #a92d2d;
  }

  .btn-creer-compte {
    @media screen and (min-width: 940px) {
      width: 400px;
    } 
    height: 100px;
    padding: 15px 12px;
    font-size: 20px;
  }

  .part {
    display: flex;
    flex-direction: row;
    justify-content: center;
    //min-height: 70vh;

    .part-content {
      @media screen and (min-width: 940px) {
        width: 1200px;
      } 
      
    }

    &.part1 {
      padding-bottom: 80px;
      @media screen and (min-width: 940px) {
        min-height: 25vh;
      }

      .logo-accueil {
        @media screen and (max-width: 940px) {
          text-align: center;
        }
      }
      
      background-image: url('/app/auth/_common/images/background.jpg');
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: cover;
      color: $primary;

      form a {
        margin-top: 8px;
      }

      .connection {
        background-color: $secondary;
        padding: 8px 16px 5px 15px;

        h3,
        md-icon {
          color: $white;
        }
      }

      p {
        font-family: 'Aldrich', sans-serif;
        font-size: 26px;
        color: #005333;
        margin-top: 50px;
      }

      .inscription-synel {
        md-card {
          height: 10rem;
          margin: 0;
          md-card-title {
            min-height: 40px;
          }
          md-card-content {
            padding-bottom: 0.2rem;
          }
          md-card-actions {
            margin-bottom: 1.5rem;
          }
        }
      }
      
    }
    .btn-creer-compte {
      @media screen and (min-width: 940px) {
        width: 400px;
      } 
      height: 100px;
      padding: 15px 12px;
      font-size: 20px;
      line-height: 70px; /* positionner le texte verticalement : moitié de la heuteur + font-size */
    }

    &.part2 {
      color: $primary-dark;

      .experience {

        //margin-top: 10px;
        //margin-bottom: 10px;
        
        @media screen and (min-width: 940px) {
          transform: translateY(-70px);
          padding: 30px 100px 62px;
        }       
        background-color: $white;

        .media {
          @media screen and (min-width: 940px) {
            margin-right: 60px;

          }
          @media screen and (max-width: 940px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        img {
          width: 100%;
        }

        h3 {
          margin-top: 0;
        }

        .contact {
          margin-top: 60px;

          .actions {
            .md-button:first-child {
              margin-left: 0;
            }
          }
        }

        .md-button {
          width: 177px;
        }
      }

      .inscription {
        margin-left: 100px;

        h3 {
          margin-top: 0;
        }

        .md-button {
          width: 177px;
          margin-left: 0;
          margin-top: 20px;
        }

        img {
          width: 100%;
        }
      }
    }

    .video-link {
      margin-top: -15px;
    }

    .video{
      @media screen and (min-width: 940px) {
        width: 560px;
        height: 315px;
      }
    }
    .piloter {
      @media screen and (max-width: 940px) {
        padding: 10px;
      }
    }

    &.part3 {
      flex-direction: column;
      justify-content: start;
      align-items: center;
      background-color: $primary-light;
      color: $white;

      .error-message,
      div[ng-messages] {
        color: #842525;
      }

      .part-content {
        margin: 40px 0 20px;
      }

      h3,
      md-icon {
        color: $white;
      }

      p {
        margin-bottom: 30px;
      }

      form {
         margin-left: 100px;
        @media screen and (max-width: 940px) {
          align-items: center;
          margin-left: 0px;
        }
        input,
        select {
          width: 300px;
        }

        textarea {
          @media screen and (min-width: 940px) {
            width: 400px;
            height: 130px;
          }
          @media screen and (max-width: 940px) {
            width: 300px;
            height: 130px;
          }
          
        }

        .md-button {
          margin-left: 0;
          @media screen and (max-width: 940px) {
            margin-left: auto;
          }
        }
        
        .assistance-info {
          margin-top: 10px;
          color: $white;
        }
      }

      .img-contact{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          max-width: 350px; 
          height: 250px;
        }
        //@media screen and (max-width: 940px) {
        //display: flex;
        //align-items: center;
        //justify-content: center;
         // img{
        //    max-width: 300px; 
        //    height: 250px;
        //  }
        //}
        
         
      }

      .assistance {
        a {
          .material-icons {
            font-size: 32px;
            width: 32px;
            height: 32px;
            margin-right: 6px;
          }
          margin-bottom: 10px;
          color: $white;
          font-family: 'Aldrich', sans-serif;
        }
      }

      .footer {
        padding: 0 40px 15px;
        background-color: $white;

        h3 {
          color: $primary-dark;
        }

        p {
          text-align: center;
          color: $primary-dark;
        }
      }
    }
  }

  .grp-buttons {
    margin-bottom: 12px;
  }
}

.wrapper {
  width: 600px;
  display: flex;
  @media screen and (max-width: 940px) {
    width: 200px;
    display: contents;
  }
  justify-content: center;
}

.or-separator {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  width: 10%;
  @media screen and (max-width: 940px) {
    margin: auto;
  }
}

.vertical-line {
  border-left: 2px solid black;
  flex-grow: 1;
  width: 1px;
}

.auth-bloc-width {
  width: 45%;
  @media screen and (max-width: 940px) {
    width: 100%;
  }
}

.title-mire {
  margin-top: 0;
  margin-bottom: 10px;
  @media screen and (min-width: 940px) {
    position: relative;
    left: 36%;
  }
 
}

.button-mire-kc {
  line-height: normal;
  @media screen and (min-width: 940px) {
    position: relative;
    top: 34%;
  }
}

.margin-auto {
  margin: auto !important;
}

.margin-auto-h3 {
  @media screen and (max-width: 940px) {
    margin: auto !important;
  }
}


.button-mire-pe {
  width: 50%; 
  margin-left: auto; 
  margin-right: auto; 
  margin-bottom: 10px;
  display: block;
}
