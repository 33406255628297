
@media (max-width: 960px){
    h2 {
        font-size: 20px !important;
        line-height: 20px !important;
    }
}
.validation-identite {
   
    .informations-content {
        overflow-x: hidden;
        &:first-child {
            margin-right: 20px;
        }
        @media (max-width: 960px){
            margin-left: 10px;
        }
    }
  
    .codePays {
      display: inline-block;
      background-color: $gray;
      padding: 2px;
    }
  
    .lien {
      margin-right: 20px;
    }
  
    .exploitation {
      md-input-container .md-input, md-input-container md-select {
      width: 270px;
      }

    }

    .maxwidth {
      max-width: 300px;
    }

    h3 {
      &:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-left: 15px;
      }

      font-size: 18px;
      line-height: 21px;
      font-family: Roboto,sans-serif;
    }
  }
  