.title-bar-responsive {
  align-items: center;
  height: 50px;
  padding: 10px;
  border-bottom: 2px solid $gray;

  &.has-breadcrumbs {
    align-items: start;
    height: 110px;
    padding: 10px 40px;
  }
}

@media screen and (max-width: 940px) {
  h2 {
    text-align: center;
  }

  .breadcrumbs-responsive{
    text-align: center;
    @media screen and (max-width: 940px) {
      transform: scale(0.7);
    }
  }

  .title-bar-responsive {  
    &.has-breadcrumbs {
      height: 160px;
    }
  }
}