.partenaires {

  .partenaires-list {
    margin-top: 5%;
  }

  .partenaires-item {
    margin-right: 30px;
  }

  img {
    max-width: 200px;
    max-height: 200px;
  }
  
}
  