.selection-exploitation {
  min-height: auto;

  .md-dialog-content {
    padding: 20px;
  }

  md-card {
    height: 100%;
    padding-top: 5px;
    margin-bottom: 20px;
    
    &.active {
      background-color: $secondary;
    }
  }

  .menu-item {
    display: flex;
    align-items: center;
    font-size: 12px;
    span {
      color: white;
      &.multi {
        max-width: 170px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &.account {
      background-color: $secondary;
    }
  }

}

md-dialog.selection-exploitation {
  width: auto;
}
