.creation-compte {

  .creation-compte-content {
    margin-left: 22%;
    margin-top: 20px;
  }

  .adv-button.adv-icon-button {
    margin: 0 6px;
    height: 40px;
    min-width: 0;
    line-height: 24px;
    padding: 8px;
    width: 40px;
    border-radius: 50%;

    i {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .adv-button {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.01em;

    &:not([disabled]):hover {
      background-color: rgb(178,178,178);
    }
    display: inline-block;
    position: relative;
    cursor: pointer;
    min-height: 36px;
    min-width: 112px;
    line-height: 36px;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    text-align: center;
    border-radius: 3px;
    box-sizing: border-box;
    user-select: none;
    outline: none;
    border: 0;
    padding: 0 6px;
    margin: 6px 8px;
    background: transparent;
    color: currentColor;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    font-style: inherit;
    font-variant: inherit;
    font-family: inherit;
    text-decoration: none;
    overflow: hidden;
    -webkit-transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

    &.active,
    &:active {
      box-shadow: none;
    }

    &.adv-raised {
      color: rgb(33,33,33);
      background-color: rgb(213,213,213);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);

      &[disabled] {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.26);
      }
    }

    &[disabled] {
      opacity: 0.65;
    }

    &.adv-primary {
      color: $black;
    }

    &.adv-big-button {
      min-width: 150px;
      min-height: 60px;
      line-height: 24px;
      padding-top: 5px;
    }

    &.adv-large-button {
      min-width: 200px;
    }
  }
  /* BUTTON GROUP */
  .btn-group,
  .btn-group-vertical {

    margin-left: 65px;
    margin-top: -38px;
    button {
      .tick {
        display: none;
        font-size: 20px;
        color: $black;
      }

      &.active {
        background-color: $secondary;
        color: $white;

        .tick {
          display: inline-block;
          margin-left: 8px;
        }

        &:hover {
          background-color: $secondary;
          color: $white;
        }
      }

      &.adv-big-button {
        .tick {
          position: absolute;
          font-size: 27px;
          top: 2px;
          right: 4px;
        }

        &:last-child {
          .tick {
            right: 16px;
          }
        }
      }
    }
  }

  .btn-group {
    button {
      border-radius: 0;
      margin-left: 0;
      margin-right: 0;

      &:not(:first-child) {
        margin-left: 5px;
      }

      &:first-child {
        border-top-left-radius: 18px;
        border-bottom-left-radius: 18px;
      }

      &:last-child {
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
      }

      &.adv-big-button {
        &:first-child {
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
        }

        &:last-child {
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
        }
      }
    }
  }

  .btn-group-vertical {
    button {
      width: 100%;
      margin-bottom: 10px !important;
      border-radius: 0;

      &:not(:first-child) {
        margin-left: 0;
      }

      &.text-left {
        text-align: left;
      }
    }
  }

  .compte-profil {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 160px;
  }

  md-card-header {
    padding: 7px 16px;
    border-bottom: 2px solid $gray-light;
    color: $primary;
  }

  .icon-arrow-right {
    color: $secondary;
    margin-left: 50px;
  }

  .icon-arrow-left {
    color: $red;
    margin-right: 50px;
  }

  .compte-list{
    &:nth-of-type(odd) {
      background-color: $gray-light;
    }

    &:nth-of-type(even) {
      background-color: $white;
    }
  }

  .compte-col-left {
    margin-right: 50px;
  }
}
