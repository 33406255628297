.bgta {

  .bgta-identifiant {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 20px;

    .label {
      margin-right: 20px;
      text-align: right;
      font-family: 'Aldrich', sans-serif;
      font-weight: bold;
      color: $primary;
    }

    .value {
      color: $primary-dark;
    }
  }

  .bgta-content {
    margin-left: 38%;
    text-align: left;
  }

}
