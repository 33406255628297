.fonctionnalite {
  md-content {
    overflow: hidden;
  }

  iframe {
    overflow: hidden;
    border: none;
    width: 100%;
    height: 100%;
  }
}
