.commandesIA {

      // centrer les éléments internes (liste des boutons avec répartition uniforme selon l'espace disponible)
      .parent {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 100%;
      }

      // aspect complémentaires des boutons
      .service {    
        text-transform: capitalize !important;/*For Lower case use lowercase*/
        font-weight : bold;
        font-size: large;
        font-family: 'Aldrich', sans-serif;
        width: 200%;
        display: block;
        padding: 4px;
    }
    
}