.souffleur-button {
  md-icon.active-icon {
    display: none;
    position: absolute;
    color: $black;
  }

  &.active {
    .active-icon {
      display: block;
      height: 12px;
      width: 12px;
      min-height: 12px;
      min-width: 12px;
      top: 6px;
      right: 10px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
