.header-bar {
  z-index: 1;
  min-height: 50px;
  overflow-x: auto;
  overflow-y: hidden;
  .logo {
    height: 36px;
    margin-right: 30px;
  }

  .production-link {
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    align-self: flex-end;
    padding: 0 16px;
    max-height: 40px;

    &.active {
      font-weight: bold;
      font-size: 20px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
 
      &.bov {
        background-color: $primary;
      }

      &.woc {
        background-color: $purple;
      }
    }
  }

  .md-toolbar-tools {
    height: 50px;
    max-height: 50px;
    font-size: 17px;
  }

  h2 {
    color: $white;
  }

  * {
    color: $white !important;
  }

  md-menu-bar {
    @media screen and (min-width: 600px) {
      height: 100%;
    }
    padding: 0;

    md-menu {
      height: 100%;
    }
  }

  .menu-item {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    margin-right: -16px;

    span {
      font-size: 15px;
    }

    .num-exploitation {
      font-size: 12px;
    }

    md-icon {
      margin-left: 4px;
    }

    &.account {
      background-color: $secondary;
      font-size: 15px;
      margin-left: 6px;
    }
  }

}

md-menu-content.header-menu {
  &.md-menu-bar-menu.md-dense {
    padding: 10px 0;
  }

  md-menu-item {
    color: $secondary;

    .two-lines {
      div {
        line-height: 14px;
      }
    }

    .accent {
      color: $primary-dark;
    }

    md-icon {
      fill: $secondary;
      color: $secondary;
    }
  }

  a, [ng-click] {
    color: $secondary;
  }
}

.div-item {
  padding-left: 24px;
  padding-right: 10px;
  padding-bottom: 4px;
  background-color:#EBEAEA;
}

.icon-assistance {
  fill: $secondary;
  color: $secondary;
}

.text-assistance {
  padding-left: 18px;
  color: $secondary;
  font-size: 13px;
}

.title-assistance {
  padding-left: 42px;
  color: $primary;
  font-size: 13px;
}

.resserrer {
  margin-bottom: -5px;
}

.espacer {
  margin-top: 10px;
}

.espace {
  margin-top: 5px;
  margin-bottom: 5px;
}

.title-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px;
  border-bottom: 2px solid $gray;

  &.has-breadcrumbs {
    align-items: start;
    height: 110px;
    padding: 10px 40px;
  }
}

.breadcrumbs {
  @media screen and (min-width: 940px) {
    margin-left: 60px;
  }
}

.actions-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  z-index: 1;
  min-height: 48px;
  height: 48px;
  padding: 0 10px;
  box-shadow: 0 -1px 3px 0 rgba(0,0,0,.2),0 -1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
}

.service-actions-bar{
  @media screen and (max-width: 940px) {
    height: 60px;
  }
}

/* TOOLBAR */
adv-toolbar {
  font-size: 20px;
  padding: 0 16px;
  @media screen and (max-width: 600px) {
    padding: 0 3px;
  }
  display: flex;
 
  flex-direction: row;
  align-items: center;
  align-content: center;
  background-color: inherit;
  .adv-button {
    margin-top: 0;
    margin-bottom: 0;
    @media screen and (max-width: 600px) {
      display: contents;
      font-size: 12px;
    }
    &:not(.adv-icon-button) {
      min-height: 28px;
      line-height: 28px;
    }
  }

  > .adv-button:first-child {
    margin-left: -8px;
  }

  i {
    color: rgba(0,0,0,0.54);
  }
}
/* MAIN TOOLBAR */
adv-toolbar.main-toolbar {
  background-color: $dark-gray;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 2px 2px rgba(0, 0, 0, 0.098), 0 0 5px 1px rgba(0, 0, 0, 0.084);
  z-index: 2;
  box-sizing: border-box;
  min-height: 48px;

  .home-button {
    line-height: 36px;
    padding: 2px;

    i {
      font-size: 36px;
    }
  }

  .adv-button.adv-icon-button i {
    color: rgba(255,255,255,0.87);
  }

  a,
  a:focus,
  a:hover {
    color: rgba(255,255,255,0.87);
  }

  .dropdown {
    margin-left: 20px;

    a:not(.dropdown-toggle) {
      color: inherit;
    }
  }
}
/* SECONDARY TOOLBAR */
adv-toolbar.secondary-toolbar {
  background-color: $white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 2px 2px rgba(0, 0, 0, 0.098), 0 0 5px 1px rgba(0, 0, 0, 0.084);
  min-height: 56px;
  height: 56px;
  color: rgba(0,0,0,0.67);
  z-index: 51;

  h2 {
    color: $light-green;
    font-size: 20px;
    margin: 0 40px 0 0;
  }

  .logo {
    height: 36px;
    margin-right: 12px;
  }
}
/* TITLE TOOLBAR */
.title-toolbar,
adv-toolbar.title-toolbar {
  
  background-color: $light-gray;
  border-bottom: 2px solid $light-green;
  color: rgba(0, 0, 0, 0.67);
  min-height: 40px;
  height: 40px;

  &.details-abo {
    border-bottom: none;
  }

  &.footer {
    border-bottom: none;
  }

  i {
    color: rgba(0, 0, 0, 0.54);
  }

  .item {
    align-items: center;
    align-content: center;
    display: flex;
    height: 32px;
    position: relative;
    margin-top: 6px;
    border: 1px solid rgba(0,0,0,0.25);
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #f3f3f3;
    @media screen and (max-width: 600px) {
      padding-right: 3px;
      padding-left: 3px;
    }

    .compteur {
      display: none;
    }

    &.active {
      height: 35px;
      border: 2px solid $light-green;
      border-bottom: none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #ffffff;

      button {
        font-weight: bold;
      }

      .compteur {
        display: inline-block;
      }
    }
    /* RGM3-4.18 */
    &.adv-alert {
      color: rgba(255,0,0, 0.8);
    }
  }

  h2 {
    color: $light-green;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 20px 0 0;
  }

  adv-icon {
    color: rgba(0,0,0,0.54);
  }
}
/* FOOTER BAR */
.footer-bar {
  background-color: $white;
  border-top: 2px solid $light-gray;
  color: rgba(0,0,0,.67);
  font-size: 14px;
  height: 44px;

  a {
    padding: 0 5px;
  }
}
/* SECTION BAR */
adv-toolbar.section-bar {
  position: relative;
  min-height: 40px;
  height: 40px;
  background-color: #fafafa;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
  z-index: 50;

  h3 {
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.67);
    margin: 0;
  }
}
/* SECTION BAR */
adv-toolbar.actions-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  z-index: 1;
  min-height: 48px;
  height: 48px;
  padding: 0 10px;
  box-shadow: 0 -1px 3px 0 rgba(0,0,0,.2),0 -1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
  background-color: #fefefe;

  &.center {
    justify-content: center;
  }

  &.left {
    justify-content: flex-start;
  }

  .recapitulatif {
    ul {
      display: flex;
      flex-direction: row;
      margin: 0;

      li {
        margin-right: 16px;
        font-size: 15px;
      }
    }
  }
}
/* SIMPLE BAR */
adv-bar {
  background-color: white;
}

.md-tooltip {
  margin-bottom: 1px !important;
  margin-left: 30px !important;
  background-color: $primary-dark !important;
  width: auto;
  height: auto;
  border-radius: 5px;
  font-size: 10px;
  color: $white !important;

}

.tooltip-content {
  padding-left: 2px;
  line-height: 8px;
}
