.admin-menu {
  background-color: $primary;

  a, .btn-abonnement {
    display: block;
    text-align: left;
    color: $white;
    font-family: 'Aldrich', sans-serif;
  }

  .niveau2 {
    font-size: 10px;
    font-weight: lighter;
    margin: 0 20px;
    color: $white;
  }

  .active {
    font-weight: bold;
    font-size: 12px;
  }
}
