@media screen and (max-width: 940px) {
  .md-radio-group-nouvel-abo {
    text-align: center;
  
    md-radio-button {
      margin-bottom: 40px;
  
      .md-container {
        top: auto;
        bottom: -40px;
        left: 50%;
      }
  
      &[disabled] {
        opacity: 0.5;
      }
    }
  }
}
@media screen and (min-width: 940px) {
  .md-radio-group-nouvel-abo {
    display: flex;
    flex-direction: row;
    text-align: center;
  
    md-radio-button {
      margin-bottom: 40px;
  
      .md-container {
        top: auto;
        bottom: -40px;
        left: 50%;
      }
  
      &[disabled] {
        opacity: 0.5;
      }
    }
  }
}




.services-abonnements {

  .devices-expert {
    width: 100px;
    height: 100px;
    color: $expert;
  }

  .devices-basic {
    width: 100px;
    height: 100px;
    color: $basic;
  }

  .devices {
    margin-top: 10px;
  }

  .service-card {
    margin-right: 50px;
    width: 300px;
  }

  .service-abonnement-card {
    background-color: $primary-dark;
  }

  .radio-button-service {
    margin-bottom: 30px;
  }

  .raison-social {
    margin-top: 30px;
    margin-right: 110px;
  }

  .service-basic-content-text {
    padding-left: 2rem;
    height: 85px;
    color: $basic;
  }

  .service-expert-content-text {
    padding-left: 2rem;
    height: 85px;
    color: $expert;
  }

  .active {
    background-color: $secondary;
    text-align: center;
    color: $white;
  }

  .niveau-basique {
    margin-top: -5px;
    font-size: 26px;
  }

  .niveau-expert {
    margin-top: -5px;
    font-style: italic;
    font-size: 26px;
  }

  .libelle {
    margin-top: -14px;
    font-size: 14px;
  }

  .tarif {
    text-align: center;
    font-size: 18px;
  }

  .prix {
    line-height: 0px;
  }

  .duree {
    text-align: center;
    font-size: 15px;
  }

  .logo-card {
      margin-top: -15px;
      width: 12rem;
      height: 2rem;
  }

  .label-abonnements {
    margin-right: 150px;
    margin-left: 200px;
  }

  .label-niveau {
    margin-right: 50px;
    margin-left: 200px;
  }

  .niveau-content {
    margin-top: 50px;
    margin-bottom: 70px;
  }

  md-checkbox:last-of-type {
    margin-left: 15px;
  }

  md-radio-button {
    box-sizing: border-box;
    display: inline-block;
    margin-bottom: 16px;
    white-space: nowrap;
    cursor: pointer;
    position: relative;
  }

  md-radio-button .md-container {
    position: absolute;
    top: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-top: 12px;
    left: 45%;
    right: auto;
  }

  .content {
    margin: 3px;
  }

  .details {
    margin-right: 20px;
  }
}

.button-abonnement {
  background-color: rgb(214, 214, 214); 
  color: black; 
  width: 110px;
  margin: 3px
}

.button-left {
  border-top-left-radius: 25px; 
  border-bottom-left-radius: 25px;
}

.button-right {
  border-top-right-radius: 25px; 
  border-bottom-right-radius: 25px;
}

.notification {
  margin: 30px 0px 20px 30px; 
  color: #3fae2a;
}
.notification-title {
  margin-left: 0px !important;
}

.texte-juridique {
  color: black;
  font-style: italic;
}

.md-icone-coche {
  position: absolute;
  right: 5px; 
  top: 2px; 
  min-height: 20px;
  min-width: 20px; 
  width: 30px; 
  height: 30px;
}

.notification-button-row {
  margin-left: auto;
  justify-content: center;
}

.notification-choix-media {
  color: black; 
  margin-top: 20px;
}

.notifiaction-margin-top {
  margin-top: 20px;
}

.notification-active-button {
  color: rgba(255,255,255,0.87);
  background-color: rgb(0,42,12);
}

.notification-unactive-button {
  color: #007d00;
  background-color: rgb(250,250,250);
 }

 .md-icon-help {
  position: absolute; 
  top: 0px; 
  @media (max-width: 550px) {
    left: -3px;
  }
  @media (min-width: 551px) {
    left: 5px;
  }
  left: 5px; 
  width: 40px; 
  height: 40px;
 }

.input-width {
    width: 250px !important; 
}

.icon-media {
  margin-left: 10px; 
  width: 22px; 
  height: 22px;
}
.confirm-dialog {
  
  p, li {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.010em;
    line-height: 1.6em;
    margin: 0.8em 0 1.6em;
    text-align: justify;
  }
}

.infos-media-externe {
  color: black;
  max-width: 580px;
}

.notification-border {
  border: dashed 1px; 
  padding: 10px;
}

.notification-flore-active-update {
  min-height: 350px;
}

.notification-flore-active-create {
  min-height: 350px;
}

.notification-flore-unactive {
  min-height: 150px;
}

.colorh3 {
  color: $secondary;
}

.cgsl {
  font-size: 11px;
  @media screen and (max-width: 600px) {
    margin-right: 20px;
    margin-left: 5px;
  }
}

.spacer {
  margin-right: 60px;
}
