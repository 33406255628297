// specificité IE
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  md-card.alerte  {
    height: 180px !important;
  }
}

md-card.alerte {
 
  @media screen and (min-width: 600px) {
    width: 760px;
  }
  height: auto;
  max-height: 180px;
  word-wrap: break-word;
  padding: 8px 8px 8px 20px;
  overflow: hidden;

  md-icon {
    width: 44px;
    height: 44px;
    padding: 5px;
    border-radius: 50%;
    margin-right: 20px;

    &.material-icons {
      width: 50px;
      height: 50px;
      font-size: 50px;
      padding: 0;
    }
  }

  h3 {
    margin: 0 0 5px 0;
  }

  p {
    margin: 0 20px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.faible, &.moyen {
    background-color: $white;

    md-icon {
      background-color: $primary;
      fill: $white;
      color: $white;
    }

    .description {
      color: $primary;
      height: auto;
      max-height: 180px;
      word-wrap: break-word;
    }
  }

  &.moyen {
    md-icon {
      background-color: $white;
      fill: $primary;
      color: $primary;
    }
  }

  &.haut {
    background-color: $primary-dark;

    md-icon {
      background-color: $white;
      fill: $red;
      color: $red;
    }

    .description {
      color: $white;
      height: auto;
      max-height: 180px;
      word-wrap: break-word;
    }
  }
}
