.confirm-cloture-abonnement {

    .raison {
        margin-left: 40px;
      }
      .md-dialog-content {
        margin-left: 50px;
      }
    
      .md-input-container {
        margin: 0;
      }

}

.modal-demande-modification-abonnement {

  .raison {
      margin-left: 40px;
    }
    .md-dialog-content {
      margin-left: 50px;
    }
  
    .content {
      padding: 5px 30px;
    }
}