.md-button {
  &.md-raised {
    &:not(.md-primary):not(.md-accent) {
      color: $secondary-dark;

      &:hover {
        color: $primary;
      }

      &:active {
        color: $primary-light;
      }
    }

    &.md-primary:active {
      background-color: $primary-light;
    }

    &.md-accent:active {
      background-color: $secondary-light;
    }
  }
}
