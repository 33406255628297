.modal-suspension {
  display: flex;
  justify-content: center;
  .title {
    width: 40%; 
    margin: auto; 
    color: white; 
    padding: 10px;
  }

  .title-color {
    background-color: rgb(0,42,12);
  }

  .img {
    float: inline-start; 
    width: 200px; 
    margin-top: 6px;
  }

  .custom-width {
    width: 920px !important;
  }

  .padding-0 {
    padding: 0 !important;
  }

  .content {
    padding: 24px; 
    width: auto;
  }

  .btn-changer-cheptel {
    position: absolute;
    left: 0px;
  }
}


.confirm-dialog {
  width: 500px;

  p, li {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.010em;
    line-height: 1.6em;
    margin: 0.8em 0 1.6em;
  }

  .bold {
    font-weight: bold;
  }
}
.md-button.race {
  min-height: 28px;
  line-height: 28px;
  margin: 0;
  text-align: left;
  text-transform: none;
}

.incitation-button {
  line-height: normal;
  white-space: normal;
  max-width: 180px;
}

.link-popin {
  @media (min-width: 650px){
    position: fixed;
    right: 24px;
  } 
  margin-left: 5px;
  margin-right: 5px; 
  color: black; 
  font-size: 10px;
 
}

/* pour afficher un exposant sans casser les interlignes */
sup { 
  vertical-align: top; 
  position: relative; 
  top: -0.25em;
}

.red-button {
  background-color: #006633 !important;
  color: white !important;
  border-radius: 5px;
  text-transform: none;
}

.red-button:hover {
  background-color: $primary-dark !important;
}
