#menu-overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
}
.break-long-menu {
  white-space: normal;
}
.menu {
  position: relative;
  @media screen and (max-width: 600px) {
    display: flex;
    overflow: auto;
  }
  .head-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 130px;
    min-height: 130px; 
    @media screen and (max-width: 600px) {
      height: 100px;
      min-height: 100px; 
    }

    .menu-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      @media screen and (max-width: 600px) {
        transform: scale(0.7);
        padding: 0 5px;
      }

      span {
        color: rgba($white, 0.87);
      }

      md-icon {
        width: 90px;
        height: 90px;
        fill: rgba($white, 0.87);
        margin: 0;
      }

      .libelle {
        font-size: 18px;
        font-family: 'Aldrich', sans-serif;
        font-weight: lighter;
      }

      .underline {
        position: absolute;
        z-index: 3;
        height: 20px;
        left: 0;
        right: 0;
        bottom: -10px;
        background-color: $secondary;
      }
    }
  }

  .head-color-menu-bov {
    background-color: $primary;
  }

  .head-color-menu-woc {
    background-color: $purple;
  }

  .sub-menu {
    z-index: 2;
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 18px 60px 10px 60px;
    height: 200px;
    min-height: 200px;
    background: $white;
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);

    .sub-menu-group {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      max-width: 550px;
      min-width: 250px;
      h3 {
        color: $secondary;
        margin: 10px 0;
      }

      .md-button {
        min-height: 28px;
        line-height: 28px;
        margin: 0;
        text-align: left;
        color: $primary-dark;
        text-transform: none;

        &.niveau2 {
          padding: 0;
        }
      }
    }

    .material-icons {
      color: $white;
    }

    .close-icon {
      position: absolute;
      z-index: 1;
      bottom: -18px;
      left: 0;
      right: 0;
      margin: auto;
      width: 36px;
      height: 36px;
      padding: 5px;
      font-size: 36px;
      background-color: $secondary;
      border-radius: 50%;
      fill: $white;
    }
  }

  &.vertical {
    display: flex;
    flex-direction: row;

    .head-menu {
      flex: 1;
      flex-direction: column;
      justify-content: start;
      min-width: 100px;
      height: auto;
      min-height: auto;

      .menu-item {
        padding: 10px;

        md-icon {
          width: 80px;
          height: 80px;
        }

        .libelle {
          display: none;
        }

        .underline {
          position: absolute;
          width: 12px;
          height: auto;
          top: 0;
          bottom: 0;
          left: auto;
          right: -6px;
          background-color: $secondary;
        }
      }
    }

    .sub-menu {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;

      flex-direction: column;
      padding: 10px 20px;
      min-width: 200px;
      height: auto;
      min-height: auto;

      .sub-menu-group {
        flex: none;
      }

      .close-icon {
        top: 0;
        bottom: 0;
        left: auto;
        right: -18px;
        transform: rotate(-90deg);
      }
    }
  }
}
