.div-option {
    margin-left: 20%; 
    margin-right: 20%;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #3fae2a;
    @media (max-width: 960px){
        margin: 2%;
    }
}

.option-title {
  margin-left: 0px !important;
  margin-bottom: 1%;
  margin-top: 1%;
}

.colorh3 {
  color: $secondary;
}
