.content-div {
    margin-left: 20%; 
    margin-right: 20%;
    @media (max-width: 1100px){
        margin-left: 3%; 
        margin-right: 3%;
    }
}

.img-offres {
    margin: auto; 
    display: flex;
    @media (max-width: 1100px){
        width: 100%;
    } 
}
.offre-caprin {
    max-width: 650px;
}
