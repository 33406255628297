.admin-menu {

  .admin-menu-content {
    margin-top: 50px;
  }

  .menu {
    min-height: 28px;
    line-height: 48px;
    margin: 2;
    text-align: left;
    color: $secondary;
    text-transform: none;
  }

  md-card {
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
  }

  md-card-header {
    padding: 7px 16px;
    border-bottom: 2px solid $gray-light;
    color: $primary;
  }
}
