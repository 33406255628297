.odd {

}

.even {
    background-color: rgba(0,0,0,.05);
}

.autorisations-acces-admin {

    .selection-form {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .codePays {
        display: inline-block;
        background-color: $gray;
        padding: 2px;
    }

    .choixInfo {
        display: inline-block;
        padding: 35px 45px 0 45px;
        font-size: 20px;
        margin-bottom: 20px;
        @media (max-width: 960px){
            margin: 0;
            padding: 0;
        }
    }

    .btn-valider {
        height: 10px;
        margin-top: 20px;
        margin-left: 90px;
    }
}

.compte-autorisations-acces {
    margin: 2%;
    overflow: auto;
    height: 100%;
    .div-form{
        flex-wrap: wrap;
        margin-top: 20px;
    }
    .form {
        min-height: 100px;
    }

    .margin-bottom{
        margin-bottom: 30px;
    }

    p {
        margin-bottom:0;
        margin-top: 10px;
    }
    .juridique {
        color: #3fae2a;
    }

    md-select:not([disabled]) md-select-value {
        color: black;
    }

    md-select {
        margin: 0;
    }
    
    md-content {
        flex-wrap: wrap;
    }

    md-input-container {
        margin: 5px;
        display: flex;
        max-width: 200px;
    }

    .min-width {
        min-width: 200px;
    }

    .admin-width-100 {
        @media (min-width: 960px){
            width: 100px!important;
        }
    }

    .admin-width-175 {
        @media (min-width: 960px){
            width: 175px!important;
        }
    }

    .admin-width-135 {
        @media (min-width: 960px){
            width: 135px!important;
        }
    }

    .searchInputContainer {
        width: 250px;
    }
    
    .searchInputContainer .md-input-message-animation:not(.md-char-counter), .searchInputContainer .md-char-counter:not(.md-char-counter) {
        padding-right: 5px;
        padding-left: 0;
        width: 200px;
      }
    

    th {
        text-align: left;
        background-color: #3fae2a;
    }

    .actionHeader {
        text-align: center;
    }

    .table {
        width: 100%;   
        border-spacing: 0; 
    }

    .editButton {
        color: black;
        size: 1em;
    }

    @media (max-width: 960px){
        md-input-container {
            margin: 20px !important;
        }
        
        .table thead {
            display: none;
        }
        
        .table tr{
            display: block;
            margin-bottom: 40px;
        }
        
        .table td {
            display: block;
            text-align: right;
        }
        
        .table td:before {
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-align: start;
        }

        .table td:empty:after {
            content: "\00a0 ";
            float: none;
            text-align: start;
        }
    }
}
