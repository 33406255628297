.md-autocomplete-suggestions-container li .highlight {
  font-weight: bold;
  color: $black;
}

.md-subheader .md-subheader-inner {
  padding: 0;
}

md-dialog.large-dialog {
  md-content {
    min-height: 60vh;
    padding: 24px;
  }

  md-dialog-actions {
    border-top: 2px solid #fafafa;
  }
}

md-dialog {
  md-dialog-content {
    display: block;
  }
}
