@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  //IE 10+ media query

  p {
    width: 100%;
  }

  @-moz-document url-prefix() {
    [layout-fill] {
      margin: 0;
      width: 100%;
      min-height: 100%;
      height: 100%;
    }
  }

  [layout-align="start stretch"],
  [layout-align] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
  }

  [layout-align="start center"],
  [layout-align="start end"],
  [layout-align="start start"],
  [layout-align="start stretch"],
  [layout-align="start"] {
    justify-content: flex-start;
  }

  [layout-align="center center"],
  [layout-align="center end"],
  [layout-align="center start"],
  [layout-align="center stretch"],
  [layout-align="center"] {
    justify-content: center;
  }

  [layout-align="end center"],
  [layout-align="end end"],
  [layout-align="end start"],
  [layout-align="end stretch"],
  [layout-align="end"] {
    justify-content: flex-end;
  }

  [layout-align="space-around center"],
  [layout-align="space-around end"],
  [layout-align="space-around start"],
  [layout-align="space-around stretch"],
  [layout-align="space-around"] {
    justify-content: space-around;
  }

  [layout-align="space-between center"],
  [layout-align="space-between end"],
  [layout-align="space-between start"],
  [layout-align="space-between stretch"],
  [layout-align="space-between"] {
    justify-content: space-between;
  }

  [layout-align="center start"],
  [layout-align="end start"],
  [layout-align="space-around start"],
  [layout-align="space-between start"],
  [layout-align="start start"] {
    align-items: flex-start;
    align-content: flex-start;
  }

  [layout-align="center center"],
  [layout-align="end center"],
  [layout-align="space-around center"],
  [layout-align="space-between center"],
  [layout-align="start center"] {
    align-items: center;
    align-content: center;
    max-width: 100%;
  }

  [layout-align="center center"] > *,
  [layout-align="end center"] > *,
  [layout-align="space-around center"] > *,
  [layout-align="space-between center"] > *,
  [layout-align="start center"] > * {
    max-width: 100%;
    box-sizing: border-box;
  }

  [layout-align="center end"],
  [layout-align="end end"],
  [layout-align="space-around end"],
  [layout-align="space-between end"],
  [layout-align="start end"] {
    align-items: flex-end;
    align-content: flex-end;
  }

  [layout-align="center stretch"],
  [layout-align="end stretch"],
  [layout-align="space-around stretch"],
  [layout-align="space-between stretch"],
  [layout-align="start stretch"] {
    align-items: stretch;
    align-content: stretch;
  }

  [flex] {
    flex: 1;
    box-sizing: border-box;
  }

  [flex="0"] {
    flex: 1 1 0;
    max-width: 0;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [flex="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }

  [layout="column"],
  [layout="row"],
  [layout] {
    display: flex;
  }

  [layout="column"] {
    flex-direction: column;
  }

  [layout="row"] {
    flex-direction: row;
  }

  [layout-padding] {
    padding: 8px;
  }

  [layout-margin] {
    margin: 8px;
  }

  [layout-wrap] {
    flex-wrap: wrap;
  }

  [layout-nowrap] {
    flex-wrap: nowrap;
  }

  [layout-fill] {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%;
  }

  img {
    border: none;
  }

  .adv-auth .part.part3 .part-content {
    -ms-flex: 1 1 auto;
  }

  .adv-auth input, .adv-auth select {
    padding-right: 0;
  }

  .account {
    width: 350px;
  }
  
  .actualite-item .description {
    -ms-flex: 1 1 auto;
  }

  .alerte p {
    -ms-flex: 1 1 auto;
  }

  .services-compte {

    .data-list-header {    
      width: 450px;
      height: 20px;
    }
  }

}
