.mentionsLegales {
  margin: 50px 200px 20px 200px;
  @media (max-width: 960px){
    margin: 2%;
  }
  p {
    text-align: justify;
  }

  .title {
    text-align: center;
    font-weight: bold;
    font-size: 15px;
  }

  .subtitle {
    margin-top: 20px;
    margin-bottom: -10px;
    font-weight: bold;
  }

  .paragraphe {
    @media (min-width: 960px){
      line-height: 5px;
  }
  }
}
