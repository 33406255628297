.perte-mdp {

  .content {
    margin-left: 20%;
    margin-top: 30px;
    width: 800px;
  }

  .codePays {
    display: inline-block;
    background-color: $gray;
    padding: 2px;
  }

  .aide {
    margin-top: -20px;
    margin-bottom: 20px;
    font-size: 12px;
  }

  .label-verification {
    margin-bottom: 10px;
  }

  .assistance {
    margin-top: -10px;
  }

  .exploitation {
    md-input-container .md-input, md-input-container md-select {
    width: 270px;
    }
  }
}
