.selection-exploitation {

  .md-dialog-content {
    min-height: 155px;
  }

  .selection-form {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .codePays {
    display: inline-block;
    background-color: $gray;
    padding: 2px;
  }

  .choixInfo {
    display: inline-block;
    padding: 35px 45px 0 45px;
    font-size: 20px;
  }

  .btn-valider {
    height: 10px;
    margin-top: 20px;
    margin-left: 90px;
  }

  .btn-annuler {
    height: 10px;
    margin-top: 20px;
  }

}