.annulation-commande {
  min-width: 800px;

  .recap-item .label {
    width: auto;
    margin: 2%;
  }

  md-input-container {
    margin-top: 40px;
  }
}
