.error-message,
div[ng-messages] {
  margin-top: 4px;
  color: $red;
  font-size: 13px;
  text-align: left;
}

md-input-container.md-input-focused label:not(.md-no-float),
md-input-container.md-input-has-placeholder label:not(.md-no-float),
md-input-container.md-input-has-value label:not(.md-no-float) {
  transform: translate3d(0,6px,0);
  font-family: 'Roboto', sans-serif;
}

md-input-container {
  margin: 20px 0;
  font-family: 'Roboto', sans-serif;

  &.no-floating-label {
    label {
      transform: none !important;
    }
  }
}

md-input-container:not(.md-block) .md-input, md-input-container md-select {
  width: 300px;
}

md-input-container:not(.md-input-invalid).md-input-focused .md-input {
  border-color: $secondary;
}

md-input-container label {
  color: $black;
}

md-input-container label.disabled {
  color: $gray-dark;
}

md-radio-group.radio-inline {
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  md-radio-button {
    margin-right: 18px;
  }
}

.required-asterisque {
  color: rgba(0, 0, 0, 0.4);
}
