.div-option {
    margin-left: 20%; 
    margin-right: 20%;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #3fae2a;
    @media (max-width: 960px){
        margin: 2%;
    }
}

.option-title {
  margin-left: 0px !important;
  margin-bottom: 1%;
  margin-top: 1%;
}

md-select {
  margin: 0;
}

.width850 {
  width: 850px;
}

.div-iban {
  @media (max-width: 700px) {
    flex-direction: column !important;
    text-align: center;
  }
}
