.footer-main {
  @media (min-width: 960px){
    height: 24px;
  }
  background-color: $primary-dark;

  .separator {
    color: $white;
    margin: 0 5px;
  }

  a {
    color: $white;
    text-decoration: underline;
    font-size: 12px;
  }

  .version {
    margin: 0 5px;
    font-size: 12px;
    color: $white;
  }

  .description {
    max-height: 104px;
    max-width: 104px;
    margin: 4px 0 10px 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.span-menu {
  @media (max-width: 800px){
    line-height: 16px;
    font-size: 12px !important;
  }
}
