$primary: #015433;
$primary-light: #3b815d;
$primary-dark: #002a0c;
$secondary: #3fae2a;
$secondary-light: #74e05b;
$secondary-dark: #007d00;
$gray-light: #fafafa;
$gray-dark: #666666;
$gray: #ebebeb;
$black: #000000;
$white: #ffffff;
$basic: #00e0ff;
$expert: #ff8c00;
$red: #dd2c00;
$light-gray: #EBEAEA;
$dark-gray: #747373;
$green: #00AA56;
$light-green: #76BD22;
$purple: #3d3d47;
@import '../bower_components/angular-material/angular-material.scss';
@import 'app/_common/directives/breadcrumbs/breadcrumbs.scss';
@import 'app/_common/directives/inputSearch/inputSearch.scss';
@import 'app/_common/directives/souffleurButton/souffleurButton.scss';
@import 'app/_common/directives/souffleur/souffleur.scss';
@import 'app/_common/services/error/error.scss';
@import 'app/_common/services/loading/loading.scss';
@import 'app/_common/services/modal/modal.scss';
@import "app/admin/home/_common/home.scss";
@import 'app/admin/main/_common/main.scss';
@import 'app/admin/clotureAbonnement/_common/clotureAbonnement.scss';
@import 'app/admin/clotureAbonnement/_common/resiliation/resiliation.scss';
@import 'app/admin/communication/_common/communication.scss';
@import "app/admin/creationCompte/creationCompte.scss";
@import 'app/admin/fonctionnalite/_common/fonctionnalite.scss';
@import "app/admin/gestionAbonnement/selection/_common/selection.scss";
@import "app/admin/gestionAbonnement/detail/_common/detail.scss";
@import 'app/admin/mentionsLegales/_common/mentionsLegales.scss';
@import 'app/admin/multicheptels/_common/selectionExploitation/selectionExploitation.scss';
@import 'app/admin/techniciens/affectation/affectation.scss';
@import 'app/admin/techniciens/gestion/gestion.scss';
@import 'app/auth/_common/auth.scss';
@import 'app/auth/guideAbonnement/guideAbonnement.scss';
@import 'app/eleveur/_common/directives/menu/menu.scss';
@import 'app/eleveur/_common/services/commandeAuto/annulationCommande/annnulationCommande.scss';
@import 'app/eleveur/_common/services/exploitation/selectionExploitation/selectionExploitation.scss';
@import "app/eleveur/changeMdp/_common/changeMdp.scss";
@import 'app/eleveur/compte/compte/templates/informations.scss';
@import 'app/eleveur/compte/compte/templates/services.scss';
@import 'app/eleveur/compte/compte/templates/confirmClotureAbonnement.scss';
@import 'app/_common/partials/autorisationsAcces/autorisationsAcces.scss';
@import 'app/_common/partials/facturation/facturation.scss';
@import 'app/_common/partials/options/options.scss';
@import 'app/_common/partials/politiqueConfidentialite/politiqueConfidentialite.scss';
@import 'app/eleveur/fonctionnalite/_common/fonctionnalite.scss';
@import 'app/eleveur/lienBgta/lienBgta.scss';
@import 'app/eleveur/lienCommandesIA/lienCommandesIA.scss';
@import 'app/eleveur/lienApplication/lienApplication.scss';
@import 'app/eleveur/bovin/home/_common/home.scss';
@import 'app/eleveur/bovin/main/_common/main.scss';
@import 'app/eleveur/woc/home/_common/home.scss';
@import 'app/eleveur/woc/main/_common/main.scss';
@import 'app/eleveur/_common/directives/actualites/actualites.scss';
@import 'app/eleveur/_common/directives/alerte/alerte.scss';
@import 'app/eleveur/bovin/_common/directives/dossierAnimal/dossierAnimal.scss';
@import 'app/eleveur/bovin/_common/directives/favoris/favoris.scss';
@import 'app/eleveur/main/main.scss';
@import 'app/footer/mentionsLegales/_common/mentionsLegales.scss';
@import 'app/footer/partenaires/_common/partenaires.scss';
@import 'app/nouvelAbonnement/informations/_common/informations.scss';
@import 'app/nouvelAbonnement/offresPilotElevage/offresPilotElevage.scss';
@import 'app/nouvelAbonnement/main/_common/main.scss';
@import 'app/nouvelAbonnement/services/_common/services.scss';
@import 'app/nouvelAbonnement/options/_common/options.scss';
@import 'app/nouvelAbonnement/validation/_common/validation.scss';
@import "app/perteMdp/_common/perteMdp.scss";
@import 'app/selectionCheptel/technicien/technicien.scss';
@import 'app/selectionCheptel/assistance/assistance.scss';
@import 'app/validationIdentite/validationIdentite.scss';
@import 'app/selectionProfil/selectionProfil.scss';
@import 'assets/styles/bars.scss';
@import 'assets/styles/buttons.scss';
@import 'assets/styles/common.scss';
@import 'assets/styles/dialog.scss';
@import 'assets/styles/form.scss';
@import 'assets/styles/ie10.scss';
@import 'assets/styles/layout.scss';
@import 'assets/styles/list.scss';
@import 'assets/styles/recapitulatif.scss';
@import 'assets/styles/tablettes.scss';
@import 'assets/styles/theme.scss';
@import 'assets/styles/typography.scss';

html {
  overflow: hidden;
}