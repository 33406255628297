.recapitulatif {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  > div {
    margin-right: 60px;
  }

  .header {
    font-family: 'Aldrich', sans-serif;
    font-weight: bold;
    color: $primary;
    margin-bottom: 10px;
  }
}
.recap-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  .label {
    width: 200px;
    margin-right: 20px;
    text-align: left;
    font-family: 'Aldrich', sans-serif;
    font-weight: bold;
    color: $primary;
  }

  .value {
    color: $primary-dark;
  }

  .md-button{
    margin-right:0; 
    padding:0; 
    height:20px; 
    min-height:1px;
  }
  
  .md-icon {
    height: 20px; 
    width: 20px; 
    min-height:1px;
  }
}
