@media
  (min-device-width: 300px)
  and (max-device-width: 1280px) {

    [layout="row"] {
      flex-direction: row;

    .flex-50-left {
      flex: 1 1 40%;
      max-width: 40%;
      padding-left: 10px;
    }

    .flex-50-right {
      flex: 1 1 60%;
      max-width: 60%;
    }
  }

  .adv-auth .part.part1 p {
    margin-left: 10px;
    font-family: 'Aldrich', sans-serif;
    font-size: 20px;
    color: #005333;
    margin-top: 50px;
  }

  .header-bar .logo {
    height: 30px;
    margin-right: 0px;
  }

}
