md-card.actualites {
  height: 420px;
  max-height: 420px;
  color: $primary-dark;

  .actualite-item {
    padding: 8px 10px 10px 10px;

    img {
      max-width: 120px;
      margin: 6px 10px 5px 0;
    }

    h4 {
      margin: 0;
      max-height: 74px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
    }

    p {
      max-height: 104px;
      margin: 4px 0 10px 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:not(:last-child) {
      border-bottom: 2px solid $gray-light;
    }
  }
}
