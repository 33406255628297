.informations-details {

  .content-infos {
    margin-top: 8%;
  }

  .organismes {
    margin-top: 28px;
  }

}

.services-details-content {

  .selection-abonnement {
    
      .selection-form {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    
      .codePays {
        display: inline-block;
        background-color: $gray;
        padding: 2px;
      }
    
      .data-list-header {
        padding: 0;
      }
    
      .data-list-header .column {
        font-size: 12px;
      }
    
      .data-list-body adv-list-item {
        font-size: 13px;
        padding: 0;
    }
    
      .choixInfo {
        display: inline-block;
        padding: 35px 45px 0 45px;
        font-size: 20px;
      }
    
      .btn-valider {
        height: 10px;
        margin-top: 20px;
        margin-left: 90px;
      }
    
      md-input-container .md-input, md-input-container md-select {
        width: 250px;
      }
    
      md-input-container .md-input-message-animation:not(.md-char-counter), md-input-container .md-char-counter:not(.md-char-counter) {
        width: 200px;
      }
    
      .souffleur .souffleur-input .md-datepicker-input-container {
        width: 75px;
      }
    
      md-icon {
        margin-left: initial;
        z-index: 1;
      }
    
      .md-datepicker-input {
        cursor: pointer;
      }
    
      .md-datepicker-triangle-button.md-button.md-icon-button {
        display: none;
      }
    }
}
.adresse {
  margin : 5px 0px;
}

#ville{
  width: 250px;
}

#codePostal,#confirmCodePostal {
  width: 50px;
}

.header-margin {
  margin-top: 14px;
}

.footer-margin {
  margin-bottom : 20px;
}
