.home {
  md-card {
    width: 360px;
    height: 260px;
    margin: 20px;
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);

    md-card-header {
      padding: 7px 16px;
      border-bottom: 2px solid $gray-light;
      color: $primary;

      .material-icons,
      md-icon {
        color: $primary;
        fill: $primary;
        height: 36px;
        width: 36px;
        min-height: 36px;
        min-width: 36px;
        font-size: 36px;
      }
    }
  }

  .promo {
    padding: 4px 10px;
    width: 1160px;
    height: auto;
    background-color: $primary;
    font-size: 13px;
    color: white;

    md-button {
      color: white;
    }
  }

  .promo-title {
    font-weight: bold; 
    text-transform: uppercase;
  }

}
