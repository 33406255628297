.admin-techniciens {

  .selection-form {
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .header-liste-tech {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .choixInfo {
    display: inline-block;
    padding: 35px 45px 0 45px;
    font-size: 20px;
  }

  .item-liste-techniciens {
    cursor: pointer;
  }

}