.compte-informations {

  .informations-content {
    margin-top: 2%;
    @media (min-width: 960px){
      margin-right: 20%;
    }
    @media (max-width: 960px){
      margin: 2%;
    }
    
  }

  .infos-global {
    margin-top: 3%;
  }

  .btn-change-mdp {
    width: 300px;
    margin-top: 5%;
    margin: auto;
  }

}
