.facturation {
    overflow: auto;
    height: 100%;
    width: 100%;

    .pas-code-recu-button {
        background-color: #006633;
        color: white !important;
        border-radius: 5px;
        text-transform: none;
    }

    .pas-code-recu-button:hover {
        background-color: $primary-dark !important;
    }

}

.facturation-content {
    margin-top: 1%;
    margin-bottom: 1%;
}

.div-adresse {
    margin-left: 25%;
    margin-right: 1%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.div-contact {
    margin-left: 1%; 
    margin-right: 25%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.label-default {
    text-align: left;
    color: #002a0c;
}

.texte {
    text-align: center;
}

.h2-custom {
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
}
