.error-dialog {
  width: 500px;

  h2 {
    color: $red;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.010em;
    line-height: 1.6em;
    margin: 0.8em 0 1.6em;
    color: $red;
  }
}
