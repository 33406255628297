.services-compte {

  md-content {
    padding: 0 40px;

    h3 {
      margin-bottom: 10px;
    }

    h2 {
      margin-left: 30px;
    }

    p {
      margin-left: 30px;
    }
  }

  .btn-annuler {
    margin-bottom: 20px;
  }

  .modification {
    color: #3fae2a;
  }
  
  .card-content {
    margin-top: 30px;
  }

  .description-abo {
    padding: 0 34px 0 8px;
    background-color: $gray-light;
  }

  md-radio-group:not(.md-radio-group-media):not(.md-radio-group-nouvel-abo) {
    display: flex;
    flex-direction: row;

    md-radio-button {
      margin-bottom: 40px;

      .md-container {
        top: auto;
        bottom: -40px;
        left: 50%;
      }

      &[disabled] {
        opacity: 0.5;
      }
    }
  }
  .md-radio-group-media {
    display: flex;
    flex-direction: row;
   width: 100%;
    md-radio-button {
      margin-bottom: 40px;

      .md-container {
        bottom: -40px;
      }

      &[disabled] {
        opacity: 0.5;
      }
    }
  }
  .promo {
    padding: 10px;
    width: 860px;
    height: 70px;
    background-color: $primary;
    font-size: 13px;
    color: white;
    margin: 0 0 30px 30px;
  }

  .promo-title {
    font-weight: bold; 
    text-transform: uppercase;
  }

  .bold {
    font-weight: bold; 
  }

  .service-card {
    width: 315px;
    height: 180px;
    margin: 0;
    background-color: $primary-dark;
  
    md-card-title {
      max-height: 35px;
      padding: 10px;

      img {
        height: 32px;
      }
    }

    md-card-content {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        margin: 0;
      }

      .titre {
        font-size: 26px;
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      md-icon.devices {
        height: 48px;
        width: 80px;
        margin-left: 10px;
      }
    }

    .contrat {
      min-height: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $white;

      p {
        margin: 0;

        &.tarif {
          font-size: 18px;
        }

        &.duree {
          font-size: 10px;
          margin-top: 5px;
        }
      }
    }

    &.active {
      .contrat {
        background-color: $secondary;
        color: $white;
      }
    }

    &.niveau-basique {
      md-card-content {
        color: $basic;
      }

      md-icon {
        fill: $basic;
      }
    }
  
    &.niveau-expert {
      md-card-content {
        color: $expert;

        p.titre {
          font-style: italic;
        }
      }

      md-icon {
        fill: $expert;
      }
    }

    &.ovin {
      md-card-content {
        color: $white;
      }

      md-icon {
        fill: $white;
      }
    }

    &.cloture {
      width: 170px;
      background-color: $light-gray;

      md-card-content {
        display: flex;
        justify-content: center;

        .material-icons {
          font-size: 60px;
          color: #989898;
        }
      }
    }
  }

  .btn-compte {
    margin-left: 50px;
    height: 40px;
  }

  .details {
    margin-right: 20px;
    @media screen and (max-width: 600px) {
      margin-right: 0px;
      margin-left: 5px;
    }
  }

  .cgsl {
    font-size: 11px;
    @media screen and (max-width: 600px) {
      margin-right: 20px;
      margin-left: 5px;
    }
  }

  .spacer {
    margin-right: 60px;
  }

  .cloturer {
    @media screen and (max-width: 600px) {
     width: 250px;
     font-size: 12px;
    }
  }
}
