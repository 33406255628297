.validation {

  .validation-content {
   
    @media (min-width: 800px){
      width: 60%;
      margin: auto;
      margin-top: 5rem;
    }
    @media (max-width: 799px){
      margin: 2%;
      text-align: justify;
    }
  }

  .abonnement {
    font-weight: bold;
    font-size: 16px;
  }

  .cgv {
    margin-top: 5rem;
  }

}
