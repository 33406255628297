.informations-abonnement {

  .informations-content {
    width: 500px;

    &:first-child {
      margin-right: 20px;
    }

    @media screen and (max-width: 940px) {
      padding-left: 10px;;
    }
  }

  .codePays {
    display: inline-block;
    background-color: $gray;
    padding: 2px;
  }

  .lien {
    margin-right: 20px;
  }

  .exploitation {
    md-input-container .md-input, md-input-container md-select {
    width: 270px;
    }
  }

  .title-step-1 {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    @media screen and (max-width: 800px) {
      justify-content: center;
      margin: 10px 10px 10px 10px;
    } 
    span {
      width: 60%; 
      @media screen and (min-width: 1400px) {
        width: 40%; 
      } 
      @media screen and (max-width: 800px) {
        width: 100%; 
      }
      font-size: 16px;
    }
  }
}
