md-card.dossierAnimal {
  position: relative;

  md-radio-group {
    margin-top: 10px;
  }

  .autocomplete-container {
    position: relative;

    md-progress-circular {
      position: absolute;
      right: 5px;
      top: 5px;
    }

    .md-input-focused,
    .md-input-has-value {
      label {
        visibility: hidden;
      }
    }

    md-input-container {
      margin-top: 0;
    }
  }

  md-radio-button .md-label {
    margin-left: 24px;
  }

  .md-button[type="submit"] {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .error-message, div[ng-messages] {
    color: #dd2c00;
    font-size: 13px;
    text-align: left;
    margin-top: -15px;
    margin-bottom: 5px;
  }

}
