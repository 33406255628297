.resiliation {

  h2 {
    font-size: 17px;
  }

  .raison {
    margin-left: 40px;
  }

  .md-dialog-content {
    margin-left: 50px;
  }

  .md-input-container {
    margin: 0;
  }

  p {
    font-size: 14px;
    margin-top: 50px;
    color: $red;
    font-weight: bold;

    &.dernier-abo {
      color: $secondary;
    }
  }

  .icone {
    margin: 55px 20px 0 0;
  }

}

md-dialog.resiliation {
  width: 800px;
  height: 520px;
}

.error_width {
  width: 330px;
}
