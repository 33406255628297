md-dialog {
  md-toolbar:not(.md-menu-toolbar) {
    background-color: $white;
    border-bottom: 2px solid $gray-light;

    md-icon {
      color: $gray-dark;
      fill: $gray-dark;
    }
  }

  &.large-dialog {
    min-width: 460px;
  }
}

.dialog-title {
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 20px;
  text-align: center;
}
// .md-dialog-backdrop:nth-of-type(even) {
//   z-index: 81;
// }
//
// .md-dialog-backdrop:nth-of-type(odd) {
//   z-index: 79;
// }
//
// .md-dialog-container:nth-of-type(even) {
//   z-index: 80;
// }
//
// .md-dialog-container:nth-of-type(odd) {
//   z-index: 82;
// }
