.divPrincipale{
    margin: 150px auto 0 auto;
    width: 40%;
    font-size: 16px;
    @media (max-width: 940px){
        width: 90%;
        margin: 0 auto 0 auto;
    }
}

.margin {
    margin: 20px 20px 20px 0px !important;
    @media (max-width: 940px){
        margin: 10px 20px 10px 0px !important;
    }
    
}

#divTitleLienApplication {
    margin-top: 10px;
}