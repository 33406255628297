md-card.favoris {
  position: relative;

  .md-button.favori {
    min-height: 28px;
    line-height: 28px;
    margin: 0;
    text-align: left;
    color: $secondary;
    text-transform: none;
  }

  .md-button.edit {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
