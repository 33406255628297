/* LIST */
adv-list {
  display: block;
  @media screen and (max-width: 1280px) {
    font-size: 14px;
  }
}

adv-list-item {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  min-height: 48px;
  height: 48px;
  color: $primary;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;

    &:not(:last-child) {
      border-right: 2px solid $gray;
    }
  }

  .adv-button:not(.adv-icon-button) {
    text-align: left;
    min-height: 48px;
    height: 48px;
    padding: 0 16px;
    margin: 0;
    display: flex;
  }

  .btn-group {
    display: flex;
    flex-direction: row;

    .adv-button {
      min-height: inherit;
    }
  }

  &.empty-list {
    padding: 0 16px;
    border: none;
    font-style: italic;

    &:not(.header):not([disabled]):not(.selected):hover {
      background-color: inherit;
    }
  }

  md-checkbox {
    margin-bottom: 0;
  }
}
/* DATA LIST */
.data-list-header {
  padding: 0 16px;
  min-height: 44px;
  height: auto;
  background: $white;
  border: 2px solid $gray;

  &:not(.adv-inset) {
    border-left: none;
    border-right: none;
  }

  /* ORDER */
  .column {
    color: $primary;
    font-weight: bold;

    &.sort {
      cursor: pointer;

      &.active {
        &:after {
          content: '\25bc';
          margin-left: 5px;
        }

        &.reverse:after {
          content: '\25b2';
        }
      }
    }
  }
}

.data-list-body {
  adv-list-item {
    padding: 0 16px;

    &:not(.header):not([disabled]):not(.selected):hover {
      background-color: rgba(158,158,158,0.2);
    }

    &.disabled {
      font-style: italic;
      background-color: #eeeeee !important;
      color: rgba(0, 0, 0, 0.5);
      cursor: not-allowed;
    }

    &.selected {
      background-color: #898989;
      color: #ffffff;
    }

    &:last-child {
      border-bottom: 2px solid $gray;
    }
  }

  &.stripped {
    adv-list-item {
      &:nth-of-type(odd) {
        background-color: $gray-light;
      }

      &:nth-of-type(even) {
        background-color: $white;
      }
    }
  }
  
  &.adv-inset {
    adv-list-item {
      border-left: 2px solid $gray;
      border-right: 2px solid $gray;
    }
  }
}
