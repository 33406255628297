.souffleur {
  display: flex;
  flex-direction: row;
  margin-left: -10px;

  .souffleur-input {
    display: block;
    width: 100%;
    height: 20px;
    padding: 4px 10px;
    font-size: 12px;
    line-height: 1;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

    @include input-placeholder-color('#9b9b9b');

    .md-datepicker-input-container {
      margin-top: 3px;
      border: none;

      .md-datepicker-input {
        padding: 0;
        font-size: 12px;
        color: #555;
      }
    }

    .md-select-value {
      min-height: auto;
      width: 100%;
      padding: 0;
      margin-top: 4px;
      border: none;
      font-weight: normal;
    }

    .md-select-icon {
      display: none;
    }
  }

  md-select {
    margin: 0;

    &:not([disabled]):focus .md-select-value {
      border: none;
    }
  }
}
